<form
  (submit)="addTask()"
  @blendInOut
  [class.isElevated]="isElevated"
  [class.mat-elevation-z3]="!isElevated"
  [class.mat-elevation-z4]="isElevated"
  class="add-task-form"
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
>
  <div class="input-wrapper">
    <div
      *ngIf="(isLoading$|async)"
      class="spinner"
    >
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <input
      #inputEl
      [tabindex]="tabindex"
      (blur)="onBlur($event)"
      [formControl]="taskSuggestionsCtrl"
      [matAutocomplete]="autoEl"
      [matAutocompleteConnectedTo]="origin"
      [mention]="tagSuggestions"
      [mentionConfig]="{triggerChar: '#', labelKey: 'title'}"
      spellcheck="false"
      [placeholder]="(doubleEnterCount > 0)
         ? (T.F.TASK.ADD_TASK_BAR.START|translate)
         : isAddToBottom
           ? isAddToBacklog
            ? (T.F.TASK.ADD_TASK_BAR.ADD_TASK_TO_BOTTOM_OF_BACKLOG|translate)
            : (T.F.TASK.ADD_TASK_BAR.ADD_TASK_TO_BOTTOM_OF_TODAY|translate)
           : isAddToBacklog
            ? (T.F.TASK.ADD_TASK_BAR.ADD_TASK_TO_TOP_OF_BACKLOG|translate)
            : (T.F.TASK.ADD_TASK_BAR.ADD_TASK_TO_TOP_OF_TODAY|translate)"
      matInput
    />

    <div class="button-controls">
      <div
        *ngIf="taskSuggestionsCtrl?.value?.length"
        class="separator-box"
      >
        <button
          class="switch-add-to-btn"
          (mousedown)="addTask()"
          type="submit"
          mat-icon-button
        >
          <mat-icon *ngIf="activatedIssueTask">add_box</mat-icon>
          <mat-icon *ngIf="!activatedIssueTask">add</mat-icon>
        </button>
      </div>

      <button
        *ngIf="isAddToBacklogAvailable$|async"
        (click)="isAddToBacklog=!isAddToBacklog"
        [matTooltip]="T.F.TASK.ADD_TASK_BAR.TOGGLE_ADD_TO_BACKLOG_TODAY|translate"
        matTooltipPosition="above"
        class="switch-add-to-btn"
        mat-icon-button
        type="button"
      >
        <mat-icon *ngIf="isAddToBacklog">library_add</mat-icon>
        <mat-icon *ngIf="!isAddToBacklog">playlist_add</mat-icon>
      </button>
      <button
        (click)="isAddToBottom=!isAddToBottom"
        [matTooltip]="T.F.TASK.ADD_TASK_BAR.TOGGLE_ADD_TOP_OR_BOTTOM|translate"
        matTooltipPosition="above"
        class="switch-add-to-bot-btn"
        mat-icon-button
        type="button"
      >
        <mat-icon *ngIf="isAddToBottom">vertical_align_bottom</mat-icon>
        <mat-icon *ngIf="!isAddToBottom">vertical_align_top</mat-icon>
      </button>
    </div>
  </div>

  <div class="info-bar">
    <ng-container *ngIf="inputVal.length > 0">
      <div
        *ngIf="!activatedIssueTask && !shortSyntaxTags.length"
        class="info-text"
      >
        {{ T.F.TASK.ADD_TASK_BAR.CREATE_TASK|translate }}
      </div>

      <ng-container *ngIf="activatedIssueTask">
        <div
          *ngIf="activatedIssueTask.issueType"
          class="info-text add-existing"
        >
          <mat-icon>playlist_add</mat-icon>
          {{ T.F.TASK.ADD_TASK_BAR.ADD_ISSUE_TASK|translate:{ issueType:
          activatedIssueTask.issueType, issueNr: activatedIssueTask.taskIssueId ||
          activatedIssueTask.issueData?.id } }}
        </div>
        <div
          *ngIf="activatedIssueTask.issueType=== undefined"
          class="info-text add-existing"
        >
          <mat-icon>playlist_add</mat-icon>
          {{ T.F.TASK.ADD_TASK_BAR.ADD_EXISTING_TASK|translate: { taskTitle:
          activatedIssueTask.title } }}
        </div>
      </ng-container>

      <ng-container *ngIf="!activatedIssueTask && shortSyntaxTags.length">
        @for (shortSyntaxTag of (shortSyntaxTags); track shortSyntaxTag.title ||
        shortSyntaxTag) {
        <tag [tag]="shortSyntaxTag"></tag>
        }
      </ng-container>
    </ng-container>

    <ng-container *ngIf="inputVal.length === 0">
      <div class="info-text">{{ T.F.TASK.ADD_TASK_BAR.EXAMPLE|translate }}</div>
    </ng-container>
  </div>

  <!-- NOTE: [autoActiveFirstOption]="taskSuggestionsCtrl.value?.length <= 3"
  causes optionActivated not to be fired :(--->
  <mat-autocomplete
    #autoEl="matAutocomplete"
    (optionActivated)="onOptionActivated($event?.option?.value)"
    (optionSelected)="addTask()"
    [displayWith]="displayWith"
  >
    @for (item of (filteredIssueSuggestions$|async); track item.taskId || (item.issueData
    && item.issueData.id)) {
    <mat-option [value]="item">
      <mat-icon
        *ngIf="item.issueType"
        [svgIcon]="item.issueType|issueIcon"
      ></mat-icon>
      <mat-icon *ngIf="!item.issueType && !item.ctx">library_books</mat-icon>
      <tag
        *ngIf="item.ctx"
        [tag]="item.ctx"
      ></tag>
      <span [innerHTML]="item?.titleHighlighted||item?.title"></span>
    </mat-option>
    }
  </mat-autocomplete>
</form>
