<ng-template #spinner>
  <full-page-spinner></full-page-spinner>
</ng-template>

<div class="page-wrapper">
  <section
    *ngIf="(reminderService.isRemindersLoaded$|async) && (scheduledTaskService.allScheduledTasks$|async) as allScheduledTasks; else spinner"
    class="component-wrapper"
    style="margin-top: 24px"
  >
    <h2>{{T.SCHEDULE.SCHEDULED_TASKS|translate}}</h2>

    <p
      *ngIf="!allScheduledTasks?.length"
      class="no-scheduled-tasks"
    >
      {{T.SCHEDULE.NO_SCHEDULED|translate}}
    </p>

    <div
      [@standardList]="allScheduledTasks?.length"
      class="tasks"
    >
      <mat-card
        appearance="outlined"
        *ngFor="let task of allScheduledTasks; trackBy: trackByFn;"
        class="scheduled-task"
      >
        <mat-card-content>
          <div class="scheduled-task-content">
            <div class="task-info">
              <div class="title">
                <inline-multiline-input
                  [resetToLastExternalValueTrigger]="task"
                  (valueEdited)="updateTaskTitleIfChanged($event.wasChanged, $event.newVal, task)"
                  [value]="task.title"
                  class="task-title"
                ></inline-multiline-input>
                <tag-list
                  [isShowProjectTagAlways]="true"
                  [task]="task"
                ></tag-list>
              </div>

              <div
                (click)="editReminder(task)"
                class="due-date"
                mat-ripple
              >
                <div class="date-and-time-left">
                  <div class="date hide-xs">
                    {{task.reminderData.remindAt|date:'EE, d MMM, '}}
                    {{task.reminderData.remindAt|date:'shortTime'}}
                  </div>
                  <div class="date show-xs-only">
                    {{task.reminderData.remindAt|date:'d MMM, '}},
                    {{task.reminderData.remindAt|date:'shortTime'}}
                  </div>

                  <div class="time-left">
                    ({{task.reminderData.remindAt|humanizeTimestamp}})
                  </div>
                </div>
                <mat-icon>alarm</mat-icon>
              </div>

              <button
                (click)="startTask(task)"
                [matTooltip]="T.SCHEDULE.START_TASK|translate"
                class="action-btn hide-xs"
                mat-icon-button
              >
                <mat-icon svgIcon="play"></mat-icon>
              </button>

              <button
                (click)="toggleToday(task.parentData)"
                *ngIf="task.parentId && task.parentData"
                [matTooltip]="(task.parentData.tagIds.includes(TODAY_TAG.id)
                    ?T.F.TASK.CMP.REMOVE_FROM_MY_DAY
                    :T.F.TASK.CMP.ADD_TO_MY_DAY)|translate"
                class="action-btn"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="task.parentData.tagIds.includes(TODAY_TAG.id)"
                  svgIcon="remove_today"
                ></mat-icon>
                <mat-icon *ngIf="!task.parentData.tagIds.includes(TODAY_TAG.id)"
                  >wb_sunny
                </mat-icon>
              </button>

              <button
                (click)="toggleToday(task)"
                *ngIf="!task.parentId"
                [matTooltip]="(task.tagIds.includes(TODAY_TAG.id)
                    ?T.F.TASK.CMP.REMOVE_FROM_MY_DAY
                    :T.F.TASK.CMP.ADD_TO_MY_DAY)|translate"
                class="action-btn"
                mat-icon-button
              >
                <mat-icon
                  *ngIf="task.tagIds.includes(TODAY_TAG.id)"
                  svgIcon="remove_today"
                ></mat-icon>
                <mat-icon *ngIf="!task.tagIds.includes(TODAY_TAG.id)">wb_sunny</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </section>

  <section
    *ngIf=" (taskRepeatCfgs$|async) as taskRepeatCfgs; else spinner"
    class="component-wrapper"
    style="margin-top: 24px"
  >
    <h2>{{T.SCHEDULE.REPEATED_TASKS|translate}}</h2>

    <p
      *ngIf="!taskRepeatCfgs?.length"
      class="no-scheduled-tasks"
    >
      {{T.SCHEDULE.NO_REPEATABLE_TASKS|translate}}
    </p>

    <div
      [@standardList]="taskRepeatCfgs?.length"
      class="repeat-task-cfgs"
    >
      <mat-card
        appearance="outlined"
        *ngFor="let taskRepeatCfg of taskRepeatCfgs; trackBy: trackByFn;"
        class="repeat-task-cfg"
      >
        <mat-card-content>
          <div class="repeat-task-cfg-content">
            <div class="task-info">
              <div class="title">
                <inline-multiline-input
                  (valueEdited)="updateRepeatableTitleIfChanged($event.wasChanged, $event.newVal, taskRepeatCfg)"
                  [value]="taskRepeatCfg.title"
                  class="task-title"
                ></inline-multiline-input>

                <tag-list
                  [isShowProjectTagAlways]="true"
                  [task]="taskRepeatCfg"
                ></tag-list>
              </div>

              <div
                (click)="editTaskRepeatCfg(taskRepeatCfg)"
                class="repeat-text-and-icon"
                mat-ripple
              >
                <div class="repeat-text">{{ getRepeatInfoText(taskRepeatCfg)}}</div>
                <mat-icon svgIcon="repeat"></mat-icon>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </section>
</div>
