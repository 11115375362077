<table
  [class.owl-dt-calendar-only-current-month]="hideOtherMonths"
  class="owl-dt-calendar-table owl-dt-calendar-month-table"
>
  <thead class="owl-dt-calendar-header">
    <tr class="owl-dt-weekdays">
      <th
        *ngFor="let weekday of weekdays"
        [attr.aria-label]="weekday.long"
        class="owl-dt-weekday"
        scope="col"
      >
        <span>{{ weekday.short }}</span>
      </th>
    </tr>
    <tr>
      <th
        aria-hidden="true"
        class="owl-dt-calendar-table-divider"
        colspan="7"
      ></th>
    </tr>
  </thead>
  <tbody
    (keydown)="handleCalendarKeydown($event)"
    (select)="selectCalendarCell($event)"
    [activeCell]="activeCell"
    [isNoMonthSquares]="isNoMonthSquares"
    [rows]="days"
    [selectMode]="selectMode"
    [selectedValues]="selectedDates"
    [todayValue]="todayDate"
    owl-date-time-calendar-body
    role="grid"
  ></tbody>
</table>
